import PageNavigation from "../components/navigation/pageNavigation";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";

import { Autoplay } from "swiper";
import { ArrowNarrowRightIcon, CheckCircleIcon } from "@heroicons/react/solid";
import PageFooter from "@components/navigation/pageFooter";
import ProjectSlider from "@components/projectSlider";

export default function Index({ statisticsData, projects, homeCTA, baseUrl }) {
  const { t } = useTranslation(["common", "index"]);

  const getStatisticsSection = () => {
    const statistics = statisticsData.data.attributes;

    return (
      <aside className="right-0 z-20 grid items-center justify-start grid-cols-2 gap-10 p-8 mx-auto text-center text-white select-none lg:rounded-l 2xl:gap-16 lg:pr-32 xl:pr-48 lg:absolute lg:-bottom-20 lg:h-40 bg-default lg:flex lg:text-left">
        {["first", "second", "third", "fourth"].map((text, index) => {
          return (
            <p key={index}>
              <span className="font-mono text-5xl">
                {statistics[text + "Heading"]}
              </span>
              <span className="block mt-2 text-xl">
                {statistics[text + "Text"]}
              </span>
            </p>
          );
        })}
      </aside>
    );
  };

  const getSuccessMark = (id, text) => {
    return (
      <p key={`success-mark-${id}`} className="flex items-center shrink-0">
        <CheckCircleIcon className="w-6 h-6 text-default shrink-0" />
        <span className="ml-2 font-semibold ">{text}</span>
      </p>
    );
  };

  return (
    <>
      <PageNavigation />
      <header className="relative">
        <div>
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            loop={true}
            autoHeight={true}
            className="relative bg-default"
          >
            {t(`index:slider`, { returnObjects: true }).map((slide, index) => {
              return (
                <SwiperSlide key={`slide-${index}`} className="bg-black">
                  <Image
                    objectFit="cover"
                    layout="fill"
                    src={slide.image.src}
                    alt={slide.image.alt}
                    className="relative z-0 block opacity-50 select-none dark:opacity-25"
                  />
                  <main className="relative z-10 min-h-[75vh] c-container !py-40 text-white select-none lg:pt-20 lg:pb-36">
                    <h1 className="text-4xl font-semibold lg:text-5xl">
                      {slide.title}
                    </h1>
                    {slide.description && (
                      <p className="mt-4 text-lg lg:text-xl lg:w-1/2">
                        {slide?.description}
                      </p>
                    )}
                    <Link href={slide.button.link}>
                      <a className="inline-block mt-10 button button-blue">
                        {slide.button.text}
                      </a>
                    </Link>
                  </main>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {getStatisticsSection()}
      </header>

      <section className="grid grid-cols-1 gap-8 mt-10 lg:mt-28 c-container lg:grid-cols-2">
        <main>
          <h3>{t("index:section-1.subtitle")}</h3>
          <h1 className="text-5xl font-semibold">
            {t("index:section-1.heading")}
          </h1>
          <p className="py-8 text-lg">
            {t("index:section-1.text")}
            <br />
            <br />
            {t("index:section-1.text-2")}
          </p>
          <div className="grid gap-4 sm:grid-cols-2">
            {t(`index:section-1.features`, { returnObjects: true }).map(
              (text, index) => {
                return getSuccessMark(index, text);
              }
            )}
          </div>
          <Link href="/about">
            <a className="inline-block mt-10 button button-blue">
              {t("moreAboutUs")}
            </a>
          </Link>
        </main>
        <aside className="relative flex flex-col items-end md:flex-row">
          <Image
            src="/assets/home/lptex-most-ke.jpeg"
            width={1008}
            height={756}
            className="rounded"
          />
          <Link href="/careers">
            <a className="z-10 flex justify-center w-full h-auto p-6 mt-2 text-white rounded md:py-8 md:mt-0 md:flex-col md:w-40 md:absolute -bottom-16 bg-default left-10 group">
              <p className="text-lg font-semibold">
                {t("careersCTA")}
                <ArrowNarrowRightIcon className="inline-block w-6 h-6 ml-2 transition transform md:block md:ml-0 md:mt-4 group-hover:translate-x-2" />
              </p>
            </a>
          </Link>
        </aside>
      </section>
      <section className="mt-12 md:mt-24">
        <article className="items-end justify-between md:flex c-container">
          <h1 className="text-4xl font-semibold">{t("projects")}</h1>
          <Link href="/projects">
            <a className="block mt-2 text-lg md:mt-0">{t("showAll")}</a>
          </Link>
        </article>
        <ProjectSlider
          projects={projects}
          baseUrl={baseUrl}
          translations={[t("showMore")]}
        />
      </section>
      <PageFooter />
    </>
  );
}

export async function getStaticProps({ locale }) {
  const baseUrl = process.env.STRAPI_URL;

  try {
    const responseStatistics = await fetch(
      `${baseUrl}/api/statistic?locale=${locale}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.STRAPI_KEY}`,
        },
      }
    );

    const projectsResponse = await fetch(
      `${baseUrl}/api/projects?locale=${locale}&pagination[start]=0&pagination[limit]=5&populate=media`,
      {
        headers: {
          Authorization: `Bearer ${process.env.STRAPI_KEY}`,
        },
      }
    );

    const statistics = await responseStatistics.json();
    const projects = await projectsResponse.json();

    return {
      props: {
        statisticsData: statistics,
        projects,
        baseUrl,
        locale: locale,
        ...(await serverSideTranslations(locale, ["common", "index"])),
      },
      revalidate: 60,
    };
  } catch (error) {
    console.log(error);

    return null;
  }
}
