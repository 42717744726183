import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Image from "next/image";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import Link from "next/link";

const ProjectSlider = ({ projects, baseUrl, translations }) => {
  return (
    <>
      <div className="py-10 text-white bg-default">
        <div className="c-container !py-0">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            navigation={{
              enabled: true,
              nextEl: ".swiper-next",
              prevEl: ".swiper-previous",
            }}
            loop={true}
          >
            {projects.data.map((project, index) => {
              const mediaData = project.attributes.media.data[0].attributes;
              const source = mediaData.formats.medium || mediaData;
              return (
                <SwiperSlide key={index} className="h-full">
                  <div className="relative items-center justify-end md:flex">
                    <div className="top-0 left-0 z-20 flex items-center pb-10 md:pb-0 md:absolute">
                      <button className="transition rounded-full swiper-previous hover:bg-white group">
                        <ArrowNarrowLeftIcon className="w-8 h-8 mx-2 transition group-hover:text-default" />
                      </button>
                      <button className="transition rounded-full swiper-next hover:bg-white group">
                        <ArrowNarrowRightIcon className="w-8 h-8 mx-2 transition group-hover:text-default" />
                      </button>
                    </div>
                    <div className="flex flex-col justify-around gap-6 md:grid md:grid-cols-2">
                      <main className="z-10 flex flex-col items-start justify-center ">
                        <h1 className="text-2xl font-semibold md:text-3xl">
                          {project.attributes.name}
                        </h1>
                        <Link href={`/project/${project.id}`}>
                          <a className="inline-block mt-10 button button-white">
                            {translations[0]}
                          </a>
                        </Link>
                      </main>
                      <aside className="flex justify-center mt-10 rounded md:justify-end md:mt-0">
                        <Image
                          src={`${baseUrl}${source.url}`}
                          height={source.height}
                          width={source.width}
                          alt={source.alternativeText}
                          className="rounded select-none"
                        />
                      </aside>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ProjectSlider;
